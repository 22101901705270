import { Label } from "@mui/icons-material";
import CellTemplateActionIcons from "pages/CommandCenter/Cards/CellTemplates/CellTemplateActionIcons";
import CellTemplateDualDate from "pages/CommandCenter/Cards/CellTemplates/CellTemplateDualDate";
import CellTemplateDualText from "pages/CommandCenter/Cards/CellTemplates/CellTemplateDualText";
import { GenericInteractionCard } from "pages/Interaction/client/components/GenericInteractionCard";
import { MediaInteractionCard } from "pages/Interaction/client/components/MediaInteractionCard";
import { NoteInteractionCard } from "pages/Interaction/client/components/NoteInteractionCard";
import { OrderInteractionCard } from "pages/Interaction/client/components/OrderInteractionCard";
import { PaymentInteractionCard } from "pages/Interaction/client/components/PaymentInteractionCard";
import { PhoneCallInteractionCard } from "pages/Interaction/client/components/PhoneCallInteractionCard";
import { PriceAgreementInteractionCard } from "pages/Interaction/client/components/PriceAgreementInteractionCard";
import { SalesPriorityInteractionCard } from "pages/Interaction/client/components/SalesPriorityInteractionCard";
import { SurveyInteractionCard } from "pages/Interaction/client/components/SurveyInteractionCard";
import { TaskInteractionCard } from "pages/Interaction/client/components/TaskInteractionCard";
import { VisitInteractionCard } from "pages/Interaction/client/components/VisitInteractionCard";
import { SalesPriorityAgreementInteractionCard } from "pages/Interaction/client/components/SalesPriorityAgreementInteractionCard";

import React from "react";
import { Button } from "react-bootstrap";

const Login = React.lazy(() => import("pages/Login/Login"));
const Home = React.lazy(() => import("pages/Home/Home"));
const SurveysPage = React.lazy(() => import("pages/Surveys/SurveysPage"));
const SurveysAddEditPage = React.lazy(
  () => import("pages/Surveys/SurveysAddEditPage")
);
const CommandCenterCreateEditPage = React.lazy(
  () => import("pages/CommandCenter/CommandCenterCreateEditPage")
);
const CommandCenterList = React.lazy(
  () => import("pages/CommandCenter/CommandCenterWorkspaceList")
);
const Logout = React.lazy(() => import("pages/Logout/Logout"));
const ExecutePage = React.lazy(() => import("pages/Execute/ExecutePage"));
const ExecutePageFinished = React.lazy(
  () => import("pages/Execute/ExecutePageFinished")
);
const PlayBooksPage = React.lazy(() => import("pages/PlayBooks/PlayBooksPage"));
const PlayBooksAddEditPage = React.lazy(
  () => import("pages/PlayBooks/PlayBooksAddEditPage")
);
const OrdersPage = React.lazy(() => import("pages/Orders/OrdersPage"));
const OrderAddPage = React.lazy(() => import("pages/Orders/OrderAddPage"));
const OrderDetail = React.lazy(() => import("pages/Orders/OrderDetail"));
const View360Page = React.lazy(() => import("pages/View360/View360Page"));
const ConfigurableLayoutAddEditPage = React.lazy(
  () => import("pages/ConfigurableLayout/client/ConfigurableLayoutAddEditPage")
);
const TeamManagementPage = React.lazy(
  () => import("pages/TeamManagement/TeamManagementPage")
);
const TimesheetsPage = React.lazy(
  () => import("pages/Timesheets/TimesheetsPage")
);
const GenericList = React.lazy(() => import("pages/GenericList/GenericList"));
const FilePanel = React.lazy(() => import("pages/Files/FilePage"));

// HIDDEN BUT NOT REMOVED AT THE REQUEST OF IVAN AND TEAMS LEADS
// const ActivateUser = React.lazy(() => import("pages/Users/ActivateUser"));
// const UsersPage = React.lazy(() => import('pages/Users/UsersPageList'));

export class DynamicComponents {
  private static componentMap = new Map<string, React.ElementType>();

  public static RegisterDefaultComponents(): void {
    //Components:
    DynamicComponents.RegisterComponent("Button", Button);
    DynamicComponents.RegisterComponent("Label", Label);
    DynamicComponents.RegisterComponent(
      "CellTemplateDualText",
      CellTemplateDualText
    );
    DynamicComponents.RegisterComponent(
      "CellTemplateDualDate",
      CellTemplateDualDate
    );
    DynamicComponents.RegisterComponent(
      "CellTemplateActionIcons",
      CellTemplateActionIcons
    );
    DynamicComponents.RegisterComponent(
      "GenericInteractionCard",
      GenericInteractionCard
    );
    DynamicComponents.RegisterComponent(
      "InteractionCard_VISIT",
      VisitInteractionCard
    );
    DynamicComponents.RegisterComponent(
      "InteractionCard_QINST",
      SurveyInteractionCard
    );
    DynamicComponents.RegisterComponent(
      "InteractionCard_TODOI",
      TaskInteractionCard
    );
    DynamicComponents.RegisterComponent(
      "InteractionCard_SLORD",
      OrderInteractionCard
    );
    DynamicComponents.RegisterComponent(
      "InteractionCard_NOTE",
      NoteInteractionCard
    );
    DynamicComponents.RegisterComponent(
      "InteractionCard_CALL",
      PhoneCallInteractionCard
    );
    DynamicComponents.RegisterComponent(
      "InteractionCard_PAYME",
      PaymentInteractionCard
    );
    DynamicComponents.RegisterComponent(
      "InteractionCard_MEDIA",
      MediaInteractionCard
    );
    DynamicComponents.RegisterComponent(
      "InteractionCard_SLSPI",
      SalesPriorityInteractionCard
    );
    DynamicComponents.RegisterComponent(
      "InteractionCard_CSPRA",
      PriceAgreementInteractionCard
    );
    DynamicComponents.RegisterComponent(
      "InteractionCard_SLSPA",
      SalesPriorityAgreementInteractionCard
    );
    //Page Components:
    DynamicComponents.RegisterComponent("Login", Login);
    DynamicComponents.RegisterComponent("Logout", Logout);
    DynamicComponents.RegisterComponent("ExecutePage", ExecutePage);
    DynamicComponents.RegisterComponent(
      "ExecutePageFinished",
      ExecutePageFinished
    );
    DynamicComponents.RegisterComponent("Home", Home);
    DynamicComponents.RegisterComponent("SurveysPage", SurveysPage);
    DynamicComponents.RegisterComponent(
      "SurveysAddEditPage",
      SurveysAddEditPage
    );
    DynamicComponents.RegisterComponent(
      "SurveysAddEditPage",
      SurveysAddEditPage
    );
    DynamicComponents.RegisterComponent("ExecutePage", ExecutePage);
    DynamicComponents.RegisterComponent("PlayBooksPage", PlayBooksPage);
    DynamicComponents.RegisterComponent(
      "PlayBooksAddEditPage",
      PlayBooksAddEditPage
    );
    DynamicComponents.RegisterComponent("View360Page", View360Page);
    DynamicComponents.RegisterComponent("OrdersPage", OrdersPage);
    DynamicComponents.RegisterComponent("OrderDetail", OrderDetail);
    DynamicComponents.RegisterComponent("OrderAddPage", OrderAddPage);
    DynamicComponents.RegisterComponent(
      "TeamManagementPage",
      TeamManagementPage
    );
    DynamicComponents.RegisterComponent("TimesheetsPage", TimesheetsPage);
    DynamicComponents.RegisterComponent("CommandCenterList", CommandCenterList);
    DynamicComponents.RegisterComponent(
      "CommandCenterCreateEditPage",
      CommandCenterCreateEditPage
    );
    DynamicComponents.RegisterComponent(
      "CommandCenterCreateEditPage",
      CommandCenterCreateEditPage
    );
    DynamicComponents.RegisterComponent(
      "ConfigurableLayoutAddEditPage",
      ConfigurableLayoutAddEditPage
    );
    DynamicComponents.RegisterComponent("GenericList", GenericList);
    DynamicComponents.RegisterComponent("FilePanel", FilePanel);

    // HIDDEN BUT NOT REMOVED AT THE REQUEST OF IVAN AND TEAMS LEADS
    // DynamicComponents.RegisterComponent("UsersPage", UsersPage);
    // DynamicComponents.RegisterComponent("ActivateUser", ActivateUser);
  }

  public static RegisterComponent(
    key: string,
    component: React.ElementType
  ): void {
    DynamicComponents.componentMap.set(key, component);
  }

  public static GetComponent(key: string): React.ElementType {
    if (DynamicComponents.componentMap.has(key)) {
      return DynamicComponents.componentMap.get(key)!;
    }
    console.error(`Error: Dynamic component not found: ${key}`);
    return null!;
  }
}
