import "./SalesPriorityAgreementInteractionCard.scss";
import {
  GenericInteractionCard,
  InteractionCardProps,
} from "./GenericInteractionCard";
import { Resources } from "resources/Resources";
import moment from "moment";
import { InteractionStatus } from "pages/Interaction/server/services/entity/Interaction";
import { ColorBadge } from "components/ColorBadge/ColorBadge";

export const SalesPriorityAgreementInteractionCard = (
  props: InteractionCardProps
) => {
  const roles = props.interaction.User?.Roles?.map((r) => r.nmRole).join("; ");

  const newStatus: InteractionStatus | undefined =
    (props.newStatus?.value ?? null) != null
      ? {
          value: props.newStatus!.value,
          dsStatus: Resources.get(
            "SalesPriorityAgreementInteractionPanel",
            props.newStatus!.value.toLowerCase() === "true"
              ? "StatusSoldIn"
              : "StatusNotSoldIn",
            "label"
          ),
          cdSemanticValue:
            props.newStatus!.value.toLowerCase() === "true"
              ? "FINALIZED"
              : "CANCELLED",
        }
      : undefined;

  return (
    <GenericInteractionCard
      interaction={props.interaction}
      type={Resources.get("InteractionPanelTitle", "SLSPA", "label")}
      code={props.code}
      icon={{
        name: "verified",
        backgroundColor: "var(--color_red_5)",
      }}
      onPress={() => {}}
    >
      <div className="SalesPriorityAgreementInteractionCard">
        <div className="priority-date-status-container">
          <div className="priority-date-container">
            {!!props.interaction.EntityFields?.nrPriority && (
              <div className="priority-container">
                <label className="priority-label">{`${Resources.get(
                  "InteractionPriority",
                  "priorityNumber",
                  "label"
                )} #${props.interaction.EntityFields?.nrPriority}`}</label>
              </div>
            )}
            {!!props.interactionDate && (
              <label className="date-label">
                {moment(props.interactionDate).format("ddd, D MMM H:mm A")}
              </label>
            )}
          </div>
          {!!newStatus && (
            <div className="status-container">
              <ColorBadge
                width="90px"
                height="16px"
                fontSize="10px"
                fontWeight="600px"
                semanticValue={newStatus.cdSemanticValue ?? ""}
                text={newStatus.dsStatus ?? ""}
              />
            </div>
          )}
        </div>
        <div>
          {!!props.interaction.EntityFields.nmPriorityDef && (
            <label className="priority-name-label">
              {props.interaction.EntityFields.nmPriorityDef}
            </label>
          )}
        </div>
        <div>
          {!!props.interaction.EntityFields.dsPriorityDef && (
            <label className="priority-description-label">
              {props.interaction.EntityFields.dsPriorityDef}
            </label>
          )}
        </div>
      </div>

      <div className="SalesPriorityInteractionCard">
        <div className="priority-user-and-status">
          {!!props.userName && (
            <div className="priority-interaction-user">
              <label>{props.userName}</label>
            </div>
          )}
        </div>
        {!!roles && (
          <div className="priority-user-roles">
            <label>{roles}</label>
          </div>
        )}
      </div>
    </GenericInteractionCard>
  );
};
