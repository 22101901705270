import Base from "./../BaseEntity";

export interface ICustomer extends Base {
  idAccount: number;
  cdAccount: string;
  idAccountType: number;
  dsName: string;
  customerStatus?: ICustomerStatus;
  dsAddress: string;
  vlCreditLimit: string;
  vlAvailableCreditLimit: string;
  attributes?: ICustomerAttribute[];
  idStatus?: string;
  nrAddress: string;
  Address: string;
  vlLatitude: number;
  vlLongitude: number;
  nmPaymentTermGroup: string;
  nmCity: string;
  classificationIds: number[];
}

export interface ICustomerStatus extends Base {
  description: string;
  status: string;
}

export interface ICustomerAttribute {
  idAttribute: number;
  vlAccountAttribute: string;
  dtLastModified: Date;
  dsAttribute: string;
  cdAttribute: string;
  idParameterType: number;
  nmParameterType?: string;
}

export interface ICustomerFilter {
  searchCode?: string;
  search?: string;
  $skip?: number;
  $top?: number;
}

export interface ICustomerClassification {
  idAccount: number;
  idClassification: number;
  dtLastModified: string;
}

export interface ICustomerConfig extends Base {
  useKpiDefinitions: boolean;
  cards: IGenericCard[];
}

export interface IGenericCard {
  id: string;
  title: string;
  source: string;
  sourceTable: string;
  flDisplayCollapse: boolean;
  configuration: any;
  type: "datatable";
  collapsed: boolean;
  flDeleted: boolean;
}

export interface IClassification {
  id: string;
  idClassification: number;
  idAccount: number;
  cdClassification: string;
  nmClassification: string;
  nrOrder: number;
  nrLevel: number;
  dtLastModified: Date;
  idParentClassification: number;
  idClassificationType: number;
  nmClassificationType: string;
  classificationLevels: IClassificationLevel[];
}

export interface IClassificationLevel {
  id: string;
  idClassificationLevel: number;
  nmClassificationLevel: string;
  cdClassificationLevel: string;
  idClassificationType: number;
  nmClassificationType: string;
  dtLastModified: Date;
}

export interface IAssetItem extends Base {
  idAssetItem: number;
  cdAssetItem: string;
  cdOriginalCode: string;
  idObjectType: string;
  idStatus: string;
  idAssetModel: number;
  assetModel: IAssetModel;
  idAccount: number;
  account: ICustomer;
  relatedRegions: string;
  idCreatorUser: number;
  idConditionObjectType: string;
  idConditionStatus: string;
  cdBarcode: string;
  cdSerialNumber: string;
  cdAsset: string;
  nmAssetItem: string;
  dtCreation: string;
  dtManufacture: string;
  dtPurchase: string;
  dtDeploy: string;
  dtExpiration: string;
  dtUninstalled: null;
  idCurrency: number;
  vlPrice: number;
  vlMaintenanceCost: number;
  vlShippingCost: number;
  cdAssetItemHist: null;
  dtLastModified: string;
  idIntegrationSystem: number;
  flIsFromAssetCensus: boolean;
}

export interface IAssetModel extends Base {
  idAssetModel: number;
  cdAssetModel: string;
  idObjectType: string;
  idStatus: string;
  idAssetType: number;
  assetType?: IAssetType;
  idCreatorUser: number;
  nmAssetModel: string;
  dsAssetModel: string;
  dsManufacturer: string;
  vlHeight: number;
  vlWidth: number;
  vlLength: number;
  dtCreation: string;
  dtLastModified: string;
  idIntegrationSystem: number;
}

export interface IAssetType extends Base {
  idAssetType: number;
  cdAssetType: string;
  nmAssetType: string;
  dsAssetType: string;
  flexi1: string;
  flexi2: string;
  flexi3: string;
  flexi4: string;
  flexi5: string;
  cdAssetTypeHist: string;
  dtLastModified: string;
}

export interface ISearchOptions {
  search: string;
  offset: number;
  limit: number;
  dataIsolation: "1" | "0" | "-1";
  dataIsolationModule?: string;
  includeFields: string;
  sortFields?: string;
  regionIds?: string;
  restrictiveWhere?: string;
  additiveWhereExclude?: string;
  additiveWhereInclude?: string;
}
export interface IHighlightText {
  value: string;
  type: "hit" | "text";
}
export interface IHighlight {
  score: number;
  path: string;
  texts: IHighlightText[];
}

export interface IRegion {
  _id: string;
  legacyRegionId: number;
  idRegionPath: string;
  name: string;
}

export interface IClassification {
  _id: string;
  legacyClassificationId: number;
  name: string;
  level: number;
  type: string;
}

export interface IAddress {
  _id: string;
  legacyAddressId: number;
  street: string;
  number: number;
  zipCode: string;
  type: string;
  isDefault: boolean;
  isSearchable: boolean;
  isBillingAddress: boolean;
  isDeliveryAddress: boolean;
  city: string;
  state: string;
  country: string;
  location?: [number, number];
}

export interface ICustomerData {
  _id: string;
  legacyAccountId: number;
  names: {
    default: string;
    corporate: string;
    display: string;
  };
  objectClassification: {
    type: string;
    status: string;
  };
  location?: [number, number];
  isServerDeleted: boolean;
  addresses?: IAddress[];
  classifications: IClassification[];
  regions: IRegion[];
  attributes: any;
  score: number;
  highlights: IHighlight[];
}
export interface ISearchResponse {
  data: ICustomerData[];
  hasNextPage: boolean;
  offset: number;
  limit: number;
  rows: number;
  params?: any;
}

export interface IExtendCustomerData extends ICustomerData {
  defaultAddress: IAddress | null;
  defaultAddressDescription: string | null;
}

export class ExtendedCustomerData implements IExtendCustomerData {
  private baseData: ICustomerData;
  private _defaultAddress: IAddress | null;
  private _defaultAddressDescription: string | null;

  constructor(baseData: ICustomerData) {
    this.baseData = baseData;
    this._defaultAddress =
      this.baseData.addresses?.find((a) => a.isDefault) ?? null;
    this._defaultAddressDescription = this.formatAddressDescription(
      this._defaultAddress
    );
  }

  public get _id() {
    return this.baseData._id;
  }

  public get legacyAccountId() {
    return this.baseData.legacyAccountId;
  }

  public get names() {
    return this.baseData.names;
  }

  public get objectClassification() {
    return this.baseData.objectClassification;
  }

  public get location() {
    return this.baseData.location;
  }

  public get isServerDeleted() {
    return this.baseData.isServerDeleted;
  }

  public get addresses() {
    return this.baseData.addresses;
  }

  public get classifications() {
    return this.baseData.classifications;
  }

  public get regions() {
    return this.baseData.regions;
  }

  public get attributes() {
    return this.baseData.attributes;
  }

  public get score() {
    return this.baseData.score;
  }

  public get highlights() {
    return this.baseData.highlights;
  }

  public get defaultAddress() {
    return this._defaultAddress;
  }

  public get defaultAddressDescription() {
    return this._defaultAddressDescription;
  }

  private formatAddressDescription(address: IAddress | null): string | null {
    if (!address) {
      return null;
    }

    const result: string[] = [];
    if (address!.street) {
      result.push(address!.street);
    }
    if (address!.number && address!.street) {
      result.push("-");
    }
    if (address!.number) {
      result.push(address!.number.toString());
    }
    if (address!.zipCode) {
      result.push(address!.zipCode);
    }
    if (address!.city) {
      result.push(address!.city);
    }
    if (address!.state) {
      result.push(address!.state);
    }
    if (address!.country) {
      result.push(address!.country);
    }
    return result.join(" ");
  }
}
export interface IExtendedSearchResponse {
  data: IExtendCustomerData[];
  hasNextPage: boolean;
  offset: number;
  limit: number;
  rows: number;
  params?: any;
}
