import { format } from "date-fns";
import { RouteType} from "models/TeamManagement/IRoute";
import { DateHelper } from "./DateHelper";
import {TimesheetEventSubTypes} from '../models/Timesheets/TimesheetEventSubTypes';
import { behaviorsDictionary } from "models/Timesheets/TimesheetEventType";

export const NO_DISTANCE_DIFFERENCE = "0.00";

export const formatTime = (time: string | undefined, utc = true) => {
  if (!time) {
    return undefined;
  }
  const date = new Date(time)
  const options: { hour: "numeric" | "2-digit" | undefined, minute: "numeric" | "2-digit" | undefined, hour12: boolean, timeZone?: string } = { hour: 'numeric', minute: 'numeric', hour12: true }
  if (utc) {
    options.timeZone = 'UTC';
  }
  return date.toLocaleString('en-US', options)
}

export const dateOnly = () => {
  const newDate = new Date(
    new Date().getTime() - new Date().getTimezoneOffset() * -1
  );
  const formattedDate = DateHelper.format(newDate, "yyyy-MM-dd");
  return formattedDate;
};


/**
 * @description Receive a date and return a formatted string
 * @param date (Date) A date value to be converted
 * @returns (string) Fri, 3 May, 9:00 PM
 */
export const friendlyDate = (date: Date) => {
  const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const weekDay = weekDays[date.getUTCDay()];
  const day = date.getUTCDate();
  const month = date.toLocaleString("en", { month: "short" });
  const hour = date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const formatDate = `${weekDay}, ${day} ${month} ${hour}`;

  return formatDate;
};

/**
 * @description Receive two dates and return a formatted string with init and end hour
 * @param dateStart (Date) A date value to be converted
 * @param dateEnd (Date) A date value to be converted
 * @returns (string) Fri, 3 May, 9AM - 9PM
 */
export const friendlyRangeDate = (dateStart: Date, dateEnd: Date) => {
  const formatTime = (d: Date) => {
    const hours = d.getHours();
    const minutes = d.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}${minutes > 0 ? `:${minutes.toString().padStart(2, '0')}` : ''}${ampm}`;
  };

  const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const weekDay = weekDays[dateStart.getUTCDay()];
  const day = dateStart.getUTCDate();
  const month = dateStart.toLocaleString("en", { month: "short" });

  const start = formatTime(dateStart);
  const end = formatTime(dateEnd);

  const formatDate = `${weekDay}, ${day} ${month} ${start} - ${end}`;

  return formatDate;
};

/**
 * Formats a Date object to an ISO string without making any conversion that might affect the time.
 * The resulting string will have the following format: yyyy-mm-ddThh:mm:ssZ
 *
 * @param {Date} date - The Date object to format.
 * @returns {string} The formatted ISO string.
 */
export function formatDateToISOString(date:  Date): string {
  return format(date, "yyyy-MM-dd") + "T" + format(date, "HH:mm:ss") + "Z";
}

export const isStatusCompleted = (status: string) => {
  return status === "Finalized";
};

export const getEventRouteType = (subtype: TimesheetEventSubTypes, behaviors?: behaviorsDictionary): RouteType => {
  switch (subtype) {
    case "Drive":
      return 'routeStart';
    case "StartShift":
      return 'shiftStart';
    case "EndShift":
    case "AutoEndShift":
      return 'routeEnd';
    case "Break":
      if (behaviors && behaviors.payableWorkDistance === "true") return "routeBreak";
      return 'unpaidBreak';
    case "Untracked":
      return 'workTime'
    default:
      return "stop";
  }
}

export const getStringUntilSecondComma = (str: string) => {
  const firstCommaIndex = str.indexOf(',');
  if (firstCommaIndex === -1) {
    return str;
  }

  const secondCommaIndex = str.indexOf(',', firstCommaIndex + 1);
  if (secondCommaIndex === -1) {
    return str.substring(0, firstCommaIndex);
  }

  return str.substring(0, secondCommaIndex);
};
